<template>
    <div class="view pa24">

        <el-tabs v-model="searchData.year" @tab-click="searchFun">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                :key="index"></el-tab-pane>
        </el-tabs>
        <el-tabs v-model="searchData.dataType" @tab-click="searchFun">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <el-tab-pane :label="item" :name="String(id)" v-for="(item, id) in typeObj" :key="id"></el-tab-pane>
        </el-tabs>
        <div class="d-flex mt20">
            <div>
                <el-select v-model="searchData.isMonth" placeholder="请选择" class="w130 mr10" @change="searchFun">
                    <el-option v-for="(item, index) in listTypeData" :key="index" :label="item.label" :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="searchData.month" placeholder="请选择" class="w100 mr10">
                    <el-option v-for="(item, index) in monthData" :key="index" :label="item.label"
                        :value="String(item.id)">
                    </el-option>
                </el-select>
                <el-input class="w200 mb10 mr10 ml10" placeholder="请输入名称关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto;">
                <el-button-group class="mr10">
                    <el-button type="primary" @click="showImportModel('simple')"
                        :disabled="!checkedCompany.pcId">导入简易表</el-button>
                    <el-button type="primary" @click="showImportModel('complete')" :disabled="!checkedCompany.pcId">导入{{
            btnName }}</el-button>
                </el-button-group>
                <el-button-group>
                    <el-button type="primary" @click="showAddModel('simple')"
                        :disabled="!checkedCompany.pcId">录入简易数据</el-button>
                    <el-button type="primary" @click="showAddModel('complete')" :disabled="!checkedCompany.pcId">录入{{
            btnName }}</el-button>
                </el-button-group>

            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" :pageSizeData="[10, 30, 50, 100, 200]"
            :showSummary="true" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
            :currentPage="currentPage" :total="total" v-show="showAllTable">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" width="90" />
                <el-table-column align="center" label="日期" show-overflow-tooltip width="120">
                    <template slot-scope="scope">
                        <div>{{ scope.row.year + "年 " + scope.row.month + "月"
                            }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="dataTypeName" align="center" label="类型" show-overflow-tooltip>

                    <!-- <template slot-scope="scope">
                        {{ typeObj[scope.row.dataType] }}
                    </template> -->
                </el-table-column>
                <el-table-column prop="userName" align="center" label="人员" show-overflow-tooltip />
                <el-table-column prop="wages" align="center" label="应发工资总额" show-overflow-tooltip width="120px">

                    <template slot-scope="scope">
                        {{ scope.row.wages | setMoney }}
                    </template>
                </el-table-column>
                <el-table-column prop="voucherCode" align="center" label="凭证号" show-overflow-tooltip />
                <el-table-column prop="remark" align="center" label="摘要" show-overflow-tooltip />
                <el-table-column prop="subject" align="center" label="科目" show-overflow-tooltip />
                <el-table-column prop="voucherMoney" align="center" label="凭证金额" show-overflow-tooltip>

                    <template slot-scope="scope">
                        {{ scope.row.voucherMoney | setMoney }}
                    </template>
                </el-table-column>
                <el-table-column prop="researchMoney" align="center" label="计入研发费用金额" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.researchMoney | setMoney }}
                    </template>
                </el-table-column>
                <el-table-column prop="difference" align="center" label="凭证-研发费=差额" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.difference | setMoney }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="120">

                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button style="color:#F56C6C" type="text" @click="delData(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <commonTable :tableData="tableData" :loading="loading" :paginationVisible="false" v-show="!showAllTable">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" width="90" />
                <el-table-column prop="month" align="center" label="日期" show-overflow-tooltip width="150">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isTotal">总计</div>
                        <div v-else>{{ scope.row.year + "年 " + scope.row.month + "月"
                            }} 合计</div>
                    </template>
                </el-table-column>
                <el-table-column prop="dataTypeName" align="center" label="类型" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="wages" align="center" label="应发工资总额" show-overflow-tooltip>

                    <template slot-scope="scope">
                        {{ scope.row.wages | setMoney }}
                    </template>
                </el-table-column>
                <el-table-column prop="voucherCode" align="center" label="凭证号" show-overflow-tooltip />
                <el-table-column prop="remark" align="center" label="摘要" show-overflow-tooltip />
                <el-table-column prop="subject" align="center" label="科目" show-overflow-tooltip />
                <el-table-column prop="voucherMoney" align="center" label="凭证金额" show-overflow-tooltip>

                    <template slot-scope="scope">
                        {{ scope.row.voucherMoney | setMoney }}
                    </template>
                </el-table-column>
                <el-table-column prop="researchMoney" align="center" label="计入研发费用金额" show-overflow-tooltip>

                    <template slot-scope="scope">
                        {{ scope.row.researchMoney | setMoney }}
                    </template>
                </el-table-column>
                <el-table-column prop="difference" align="center" label="凭证-研发费=差额" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.difference | setMoney }}
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="800px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="130px">
                <el-row v-if="updateType == 'complete'">
                    <el-col :span="12">
                        <el-form-item label="企业人员" prop="puId">
                            <el-select class="w100p" v-model="formData.puId" filterable remote clearable
                                placeholder="输入人员名称关键词，选择企业人员" :remote-method="getProjectUserList"
                                :loading="userLoading">
                                <el-option v-for="item in userData" :key="item.puId" :label="item.userName"
                                    :value="Number(item.puId)">
                                    <span style="float: left">{{ item.userName }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.work }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="dataType">
                            <el-select class="w100p" v-model="formData.dataType" clearable placeholder="请选择类型">
                                <el-option v-for="(item, id) in typeObj" :key="id" :label="item" :value="id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="年份" prop="year">
                            <!-- <el-input placeholder="请输入年份" class="suffixInput" v-model="formData.year" type="number">

                                <template slot="append">年</template>
                            </el-input> -->
                            <el-date-picker class="w100p" format="yyyy年" value-format="yyyy" v-model="formData.year"
                                type="year" placeholder="选择年">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="月份" prop="month">
                            <!-- <el-input placeholder="请输入月份" class="suffixInput" v-model="formData.month" type="number">

                                <template slot="append">月</template>
                            </el-input> -->
                            <el-select class="w100p" v-model="formData.month" clearable placeholder="请选择月份">
                                <el-option v-for="(item, index) in monthArr" :key="index" :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="摘要" prop="remark">
                            <el-input v-model="formData.remark" placeholder="请输入摘要"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="科目" prop="subject">
                            <el-input placeholder="请输入科目" v-model="formData.subject" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="凭证号" prop="voucherCode">
                            <el-input v-model="formData.voucherCode" placeholder="请输入凭证号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="updateType == 'complete'">
                        <el-form-item label="凭证金额" prop="voucherMoney">
                            <el-input placeholder="请输入凭证金额" class="suffixInput" @change="voucherMoneyChange"
                                v-model="formData.voucherMoney" type="number">

                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-else>
                        <el-form-item label="类型" prop="dataType">
                            <el-select class="w100p" v-model="formData.dataType" clearable placeholder="请选择类型">
                                <el-option v-for="(item, id) in typeObj" :key="id" :label="item" :value="id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" v-if="updateType == 'complete'">
                        <el-form-item label="应发工资总额" prop="wages">
                            <el-input placeholder="请输入凭证金额" class="suffixInput" v-model="formData.wages" type="number">

                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-else>
                        <el-form-item label="凭证金额" prop="voucherMoney">
                            <el-input placeholder="请输入凭证金额" class="suffixInput" @change="voucherMoneyChange"
                                v-model="formData.voucherMoney" type="number">

                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计入研发费金额" prop="researchMoney">
                            <el-input placeholder="请输入计入研发费金额" class="suffixInput" v-model="formData.researchMoney"
                                type="number">

                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
        <importModel :showDelOldFile="true" :showErrFile="true"
            :data="{ pcId: this.checkedCompany.pcId, companyId: userInfo.companyId, fileType: fileType }"
            downloadModelType="get" :errorDownloadUrl="errorDownloadUrl" :uploadFilesTitle="uploadFilesTitle"
            :uploadFilesVisible="uploadFilesVisible" :uploadModelUrl="uploadModelUrl"
            :downloadModelFileName="downloadModelFileName" :downloadModelUrl="downloadModelUrl"
            @UploadFilesSuccess="filesSuccess" @uploadFilesDialogClose="hideModel" @onConfirmUploadFiles="searchFun" />
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import importModel from "@/components/common/importModel";
import { setProjectMoneyUser, getProjectMoneyUserList, delProjectMoneyUser, getProjectUserList, setProjectMoneyData, delProjectMoneyData } from "@/api/projectMoney";
export default {
    name: "costEnterTable1",
    components: {
        commonTable,
        selectCompanyList,
        importModel
    },
    data() {
        return {
            uploadFilesTitle: "导入人工费用",
            downloadModelFileName: "人工费用模板",
            uploadModelUrl: "project/insertAllProjectMoneyUser",
            downloadModelUrl: "project/downloadProjectMoney",
            errorDownloadUrl: "project/downloadWordFile?fileName=",
            uploadFilesVisible: false,
            listTypeData: [
                {
                    label: "详细",
                    id: false
                },
                {
                    label: "月度",
                    id: true
                }
            ],
            monthData: [
                {
                    label: "全部",
                    id: "all"
                },
                {
                    label: "1月",
                    id: 1
                },
                {
                    label: "2月",
                    id: 2
                },
                {
                    label: "3月",
                    id: 3
                },
                {
                    label: "4月",
                    id: 4
                },
                {
                    label: "5月",
                    id: 5
                },
                {
                    label: "6月",
                    id: 6
                },
                {
                    label: "7月",
                    id: 7
                },
                {
                    label: "8月",
                    id: 8
                },
                {
                    label: "9月",
                    id: 9
                },
                {
                    label: "10月",
                    id: 10
                },
                {
                    label: "11月",
                    id: 11
                },
                {
                    label: "12月",
                    id: 12
                }
            ],
            monthArr: [
                {
                    label: "1月",
                    id: 1
                },
                {
                    label: "2月",
                    id: 2
                },
                {
                    label: "3月",
                    id: 3
                },
                {
                    label: "4月",
                    id: 4
                },
                {
                    label: "5月",
                    id: 5
                },
                {
                    label: "6月",
                    id: 6
                },
                {
                    label: "7月",
                    id: 7
                },
                {
                    label: "8月",
                    id: 8
                },
                {
                    label: "9月",
                    id: 9
                },
                {
                    label: "10月",
                    id: 10
                },
                {
                    label: "11月",
                    id: 11
                },
                {
                    label: "12月",
                    id: 12
                }
            ],
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            yearData: [],
            tableData: [],
            searchData: { name: "", dataType: "all", year: "all", month: "all", isMonth: false },
            typeObj: {
                1: "工资计算",
                2: "社保",
                3: "公积金",
                4: "劳务费",
            },
            remarkObj: {
                1: "计提本月工资",
                2: "缴纳本月社保",
                3: "缴纳本月公积金",
            },
            subjectObj: {
                1: "研发人员工资",
                2: "研发人员社保",
                3: "研发人员公积金",
                4: "外援劳务费",
            },
            checkedCompany: {},
            //模态框配置
            modelTitle: "",
            formData: {
                puId: "",
                dataType: "",//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: "",//年份
                month: "",//月份
                remark: "",//摘要
                researchMoney: "",//	计入研发费金额
                subject: "",//科目
                voucherCode: "",//凭证号
                voucherMoney: "",//凭证金额
                wages: "",//应发工资总额
            },
            updateType: "",
            userInfo: this.$store.state.loginRoot.userInfo,
            userData: [],
            userLoading: false,
            rules: {
                puId: {
                    required: true,
                    trigger: "change",
                    message: "请输入企业人员",
                },
                dataType: {
                    required: true,
                    trigger: "change",
                    message: "请输入类型",
                },
                year: {
                    required: true,
                    trigger: "blur",
                    message: "请输入年份",
                },
                month: {
                    required: true,
                    trigger: "blur",
                    message: "请输入月份",
                },
                remark: {
                    required: true,
                    trigger: "blur",
                    message: "请输入摘要",
                },
                researchMoney: {
                    required: true,
                    trigger: "blur",
                    message: "请输入计入研发费金额",
                },
                subject: {
                    required: true,
                    trigger: "blur",
                    message: "请输入科目",
                },
                voucherCode: {
                    required: true,
                    trigger: "blur",
                    message: "请输入凭证号",
                },
                voucherMoney: {
                    required: true,
                    trigger: "blur",
                    message: "请输入凭证金额",
                },
                wages: {
                    required: true,
                    trigger: "blur",
                    message: "请输入应发工资总额",
                },
            },
            formType: "",
            updateId: 0,
            loadingBtn: false,
            fileType: 0,
            showAllTable: true,
            isEasy: false,
            btnName: "人工费用",
            
        };
    },
    mounted() {
       //-console.log(this.$root.$mp);
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.downloadModelUrl = "project/downloadProjectMoney?fileType=" + this.fileType;
        if (this.$route.query.year) {
            this.searchData.year = this.$route.query.year;
        }
        //获取年份
        this.getYearData();
        this.getProjectUserList();
        this.getList();
    },
    filters: {
        setMoney(money) {
            return (money ? money : 0).toFixed(2)
        }
    },
    methods: {
        setBtnName() {
            if (this.searchData.dataType == 'all') {
                this.btnName = "人工费用"
            } else {
                this.btnName = this.typeObj[this.searchData.dataType];
            }
            this.uploadFilesTitle = "导入" + this.btnName;
            this.downloadModelFileName = this.btnName + "模板";
        },
        showImportModel(type) {
            if (type == 'simple') {
                this.uploadFilesTitle = "简易-导入" + this.btnName;
                this.downloadModelFileName = "简易-" + this.btnName + "模板";
                this.uploadModelUrl = "project/insertAllProjectMoneyData";
                this.downloadModelUrl = "project/downloadProjectMoney?isEasy=true&fileType=" + this.fileType;
                this.errorDownloadUrl = "project/downloadWordFile?fileName=";
            } else {
                this.uploadFilesTitle = "导入" + this.btnName;
                this.downloadModelFileName = this.btnName + "模板";
                this.uploadModelUrl = "project/insertAllProjectMoneyUser";
                this.downloadModelUrl = "project/downloadProjectMoney?fileType=" + this.fileType;
                this.errorDownloadUrl = "project/downloadWordFile?fileName=";
            }
            this.uploadFilesVisible = true;
        },
        voucherMoneyChange() {
            if (this.formData.voucherMoney) {
                this.formData.wages = this.formData.wages ? this.formData.wages : this.formData.voucherMoney;
                this.formData.researchMoney = this.formData.researchMoney ? this.formData.researchMoney : this.formData.voucherMoney;
            }
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchFun();
        },
        filesSuccess() {
            this.searchFun();
            // this.uploadFilesVisible = false;
        },
        hideModel() {
            this.uploadFilesVisible = false;
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData=[];
let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
this. yearData= yearData;
        },
        /**@method 获取人员列表 */
        async getProjectUserList(str) {
            let params = {
                pageSize: 100,
                pageNum: 1,
                userName: str,
                pcId: this.checkedCompany.pcId
            };
            try {
                this.userData = [];
                let result = await getProjectUserList(params)
                const { data } = result;
                this.userLoading = false;
                this.userData = data.pageInfo.list;
            } catch (error) {
                this.userLoading = false;
                this.userData = [];
            }
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage=1;
            this.setBtnName();
            this.getList();
        },
        addUpdate() {
            //修改添加人工费用
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        pcId: this.checkedCompany.pcId,
                    };
                    let message = "录入成功";
                    if (this.updateId) {
                        params.mdId = this.updateId;
                        message = "修改成功"
                    }
                    this.loadingBtn = true;
                    if (this.updateType == 'simple') {
                        delete params.puId
                        delete params.wages
                        params.fileType = this.fileType;
                        setProjectMoneyData(params).then(res => {
                            this.loadingBtn = false;
                            if (res.code === 200) {
                                this.$message.success(message)
                                this.searchFun();
                                this.showModel = false;
                            } else {
                                this.$message.error(res.message);
                            }
                        }).catch(err => {
                            this.loadingBtn = false;
                            if (err.code === 201) {
                                this.$message.error(err.message);
                            }
                        })
                    } else {
                        setProjectMoneyUser(params).then(res => {
                            this.loadingBtn = false;
                            if (res.code === 200) {
                                this.$message.success(message)
                                this.searchFun();
                                this.showModel = false;
                            } else {
                                this.$message.error(res.message);
                            }
                        }).catch(err => {
                            this.loadingBtn = false;
                            if (err.code === 201) {
                                this.$message.error(err.message);
                            }
                        })
                    }
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                userName: this.searchData.name,
                sortByMonth: true,
                sortByYear: true,
                monthDesc: false,
                isMonth: this.searchData.isMonth
            };
            this.showAllTable = !this.searchData.isMonth;
            if (this.searchData.isMonth) {
                delete params.pageSize;
                delete params.pageNum;
            }
            if (this.searchData.month != 'all') {
                params.month = this.searchData.month;
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            if (this.searchData.dataType != 'all') {
                params.dataType = this.searchData.dataType
            }
            if (this.searchData.year != 'all') {
                params.year = this.searchData.year
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyUserList(params);
                this.loading = false;
                const { data } = result;
                let tableData = [];
                if (this.searchData.isMonth) {
                    for (let row of data) {
                        row.dataTypeName = this.typeObj[row.dataType];
                        row.difference = Math.abs(row.voucherMoney - row.researchMoney);
                        row.difference = Math.abs(row.voucherMoney - row.researchMoney);
                        tableData.push(row)
                    }
                } else {
                    for (let row of data.pageInfo.list) {
                        row.dataTypeName = this.typeObj[row.dataType];
                        row.difference = Math.abs(row.voucherMoney - row.researchMoney);
                        row.difference = Math.abs(row.voucherMoney - row.researchMoney);
                        tableData.push(row)
                    }
                    this.total = data.pageInfo.total;
                }
                this.isEasy = data.isEasy
                this.tableData = tableData;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示录入模态框 */
        showAddModel(type) {
            this.formType = "add";
            this.updateType = type;
            if (type == 'simple') {
                this.modelTitle = "简易-录入" + this.btnName;
            } else {
                this.modelTitle = "录入" + this.btnName;
            }
            this.updateId = 0;
            this.showModel = true;
            this.formData = {
                puId: "",
                dataType: this.searchData.dataType != 'all' ? this.searchData.dataType : "",//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: this.searchData.year != 'all' ? this.searchData.year : String(new Date().getFullYear()),//年份
                month: new Date().getMonth() + 1,//月份
                remark: this.searchData.dataType != 'all' ? this.remarkObj[this.searchData.dataType] : "",//摘要
                researchMoney: "",//	计入研发费金额
                subject: this.searchData.dataType != 'all' ? this.subjectObj[this.searchData.dataType] : "",//科目
                voucherCode: "记-",//凭证号
                voucherMoney: "",//凭证金额
                wages: "",//应发工资总额
            };
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.formType = "update";
            this.updateId = val.mdId;
            if (this.isEasy) {
                this.updateType = "simple"
                this.modelTitle = "简易-编辑" + this.btnName;
            } else {
                this.updateType = "complete"
                this.getProjectUserList(val.userName);
                this.modelTitle = "编辑" + this.btnName;
            }
            this.formData = {
                puId: Number(val.puId),
                dataType: String(val.dataType),//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: String(val.year),//年份
                month: val.month,//月份
                remark: val.remark,//摘要
                researchMoney: val.researchMoney,//	计入研发费金额
                subject: val.subject,//科目
                voucherCode: val.voucherCode,//凭证号
                voucherMoney: val.voucherMoney,//凭证金额
                wages: val.wages,//应发工资总额
            };
        },
        delData(row) {
            this.$confirm("确定要继续删除这条" + this.btnName, "删除" + this.btnName + "数据", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                let params = {
                    mdId: row.mdId
                }
                if (this.isEasy) {
                    delProjectMoneyData(params).then(res => {
                        if (res.code === 200) {
                            this.$message.success('删除完成');
                            this.showModel = false;
                            this.searchFun();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                } else {
                    delProjectMoneyUser(params).then(res => {
                        if (res.code === 200) {
                            this.$message.success('删除完成');
                            this.showModel = false;
                            this.searchFun();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>